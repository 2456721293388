import React, { useState, useEffect } from "react";
import { Popup } from 'devextreme-react/popup';
import DatePicker, { registerLocale } from "react-datepicker";
import fi from "date-fns/locale/fi"; // the locale you want
 // register it with the name you want
require('react-datepicker/dist/react-datepicker.css')

let getMonths = function(startDate){
    let endDate = new Date(startDate.setMonth(startDate.getMonth()+11))
    new Date(startDate.setMonth(startDate.getMonth()-11))
    let resultList = {};
    let monthNameList = ["Tammikuu", "Helmikuu", "Maaliskuu", "Huhtikuu", "Toukokuu", "Kesäkuu", "Heinäkuu", 
    "Elokuu", "Syyskuu", "Lokakuu", "Marraskuu", "Joulukuu"];
  
    while (startDate <= endDate)
    {
      resultList[startDate.getMonth() + 1 + "/" + startDate.getFullYear().toString().substring(2)] = monthNameList[startDate.getMonth()] + " " + startDate.getFullYear().toString().substring(2)
     
      startDate.setMonth(startDate.getMonth() + 1);
    }
    
    return resultList;
};

const Calendar = ({type, state, setPopupVisible, setData}) => {

    function createMonthsAndDates(d){
        let firstDate = d.getTime();
        let endDate;
        let months;
            
            months = getMonths(d); 
            console.log(months)          
            console.log(new Date(firstDate)) 
            firstDate = new Date(firstDate)
            endDate = new Date(firstDate.setMonth(firstDate.getMonth()+12))
            endDate = new Date(endDate.setDate(endDate.getDate() - 1))
            new Date(firstDate.setMonth(firstDate.getMonth()-12))
            console.log(endDate)
            return([[firstDate, endDate], months])
          
    }
    
    function createQuarterDates(secondD){
        let dates = [];
        dates.push(firstDate)
        dates.push(new Date(firstDate.setMonth(firstDate.getMonth()+3)))
        new Date(firstDate.setMonth(firstDate.getMonth()-3))
        dates.push(secondD)
        dates.push(new Date(secondD.setMonth(secondD.getMonth()+3)))
        new Date(secondD.setMonth(secondD.getMonth()-3))
        dates[1] = new Date(dates[1].setDate(dates[1].getDate() - 1))
        dates[3] = new Date(dates[3].setDate(dates[3].getDate() - 1))
        return(dates)
    }

    function calendarType() {
        switch(type) {
           case 'day':
             return datePicker
           case 'month':
              return monthPicker
             case 'quarter':
               return quarterPicker
           default:
             return null
         }
    }
    registerLocale("fi", fi);
    const [firstDate, setFirstDate] = useState(new Date());
    const [secondDate, setSecondDate] = useState(new Date());

    const datePicker = (
        <div className="widget-container">
        <DatePicker
        locale="fi"
        selected={firstDate}
        onChange={date => setFirstDate(date)}
        selectsStart
        startDate={firstDate}
        endDate={secondDate}
        inline
      />
      <DatePicker
        locale="fi"
        selected={secondDate}
        onChange={date => {setSecondDate(date); setPopupVisible(); setData([firstDate, secondDate])}}
        selectsEnd
        startDate={firstDate}
        endDate={secondDate}
        minDate={firstDate}
        inline
      />
        </div>
    )

    const monthPicker = (
        <div className="widget-container">
            <DatePicker
            locale="fi"
                selected={firstDate}
                onChange={date => {setPopupVisible(); setData(createMonthsAndDates(date))}}
                dateFormat="MM/yyyy"
                showMonthYearPicker
                showFullMonthYearPicker
                inline
            />
        </div>
    )

    const quarterPicker = (
        <div className="widget-container">
           <DatePicker
           locale="fi"
            selected={firstDate}
            onChange={date => setFirstDate(date)}
            dateFormat="yyyy, QQQ"
            showQuarterYearPicker
            inline
           />
            <DatePicker
            locale="fi"
            selected={secondDate}
            onChange={date => {setPopupVisible(); setSecondDate(date); setData(createQuarterDates(date))}}
            dateFormat="yyyy, QQQ"
            showQuarterYearPicker
            inline
           />
        </div>
    )

    return(
        <Popup
            visible={state}
            onHiding={() => setPopupVisible()}
            dragEnabled={false}
            closeOnOutsideClick={true}
            showTitle={true}
            title="Aikajakso"
            width={370}
            height={630}
        >
         {calendarType()}
      </Popup>
    )

}
export default Calendar;