import React, { useState, useEffect, useRef } from "react";
import axios from 'axios';
import { Chart, Series } from 'devextreme-react/chart';
import { Button } from 'devextreme-react/button';
import Calendar from '../../components/calendar/calendar'

const MostChargedUsers = () => {

  const [data, setData] = useState([]);
  const [popupVisible, setPopupVisible] = useState(false);

  useEffect(() => {
    
  }, []);

  function fetchData(data) {
    axios.post('/api/fetchdata', {function: "mcu", authorization: localStorage.getItem('token'), date: [data[0], data[1]]})
      .then((res) => {          
        console.log(res.data.rows)
        if(res.data != 'no result'){
          setData(res.data.rows)
        }else{
          setData([null])
        }
       
      }).catch((error) => {
          console.log(error)
      });     
  };
  
  return (
    Object.keys(data).length > 0 ?
    <div>
  <Button
    width={140}
    text="Aikajakso"
    type="default"
    stylingMode="outlined"
    style={{float: "right"}}
    onClick={() => {setPopupVisible(true)}}
  /> 
    <Chart id="chart" dataSource={data} rotated={true}>          
      <Series
        valueField="sum"
        argumentField="name"
        name="kWh"
        type="bar"
        color="#3ce05f" />
    </Chart>
    <Calendar type={'day'} setPopupVisible={() => setPopupVisible(!popupVisible)} state={popupVisible} setData={(d) => fetchData(d)}/>
    </div>
  
    :  <div> 
    <Button
    width={140}
    text="Aikajakso"
    type="default"
    stylingMode="outlined"
    style={{float: "right"}}
    onClick={() => {setPopupVisible(true)}}
  /> 
  <Calendar type={'day'} setPopupVisible={() => setPopupVisible(!popupVisible)} state={popupVisible} setData={(d) => fetchData(d)}/>
  </div>
  );
  
}

export default MostChargedUsers;