import React, { useState, useEffect, useRef } from "react";
import { Button } from 'devextreme-react/button';
import Calendar from '../../components/calendar/calendar'


const Report = () => {
  
  const [dates, setDates] = useState([]);
  const [popupVisible, setPopupVisible] = useState(false);

 function downloadEmployeeData (funct) {
   if(dates.length > 1){
    fetch('/api/fetchdata', {method: 'POST',headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    },  body: JSON.stringify({function: funct, authorization: localStorage.getItem('token'), date: [dates[0], dates[1]]})})
			.then(response => {
				response.blob().then(blob => {
					let url = window.URL.createObjectURL(blob);
					let a = document.createElement('a');
					a.href = url;
          if(funct == 'reportToExcel'){
            a.download = `LatausenergiaRaportti'${dates[0]}-${dates[1]}'.xlsx`;
          }else{
            a.download = `LatausenergiaRaportti'${dates[0]}-${dates[1]}'.pdf`;
          }					
					a.click();
				});
		});
   }
		
	}
  
  return (
    <div id="container">
      <Calendar type={'day'} setPopupVisible={() => setPopupVisible(!popupVisible)} state={popupVisible} setData={(d) => setDates(d)}/>
      <h3>Lataa excel tai pdf raportti latausarvoista
      <Button
          width={140}
          text="Aikajakso"
          type="default"
          stylingMode="outlined"
          style={{float: "right"}}
          onClick={() => {setPopupVisible(!popupVisible)}}
        />         
      </h3>
      <Button
                width={120}
                style={{margin: "5px"}}
                text="Excel"
                type="default"
                stylingMode="contained"
                onClick={()=>downloadEmployeeData('reportToExcel')}
              />
      <Button
                width={120}
                text="Pdf"
                type="default"
                stylingMode="contained"
                onClick={()=>downloadEmployeeData('reportToPdf')}
              />				
    </div>
  )    
}

export default Report;