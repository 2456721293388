import React, { useState } from "react";
import axios from 'axios';
import DataGrid, {} from 'devextreme-react/data-grid';
import { Button } from 'devextreme-react/button';
import Calendar from '../../components/calendar/calendar'

const MeasurementsByUsers = () => {

  const [data, setData] = useState([]);
  const [popupVisible, setPopupVisible] = useState(false);

  function fetchData(data) {
    if(data.length > 1){
      let chartData = {"user": ["Käyttäjä"], "months": data[1], "values": []}
      axios.post('/api/fetchdata', {function: "mbu", authorization: localStorage.getItem('token'), date: [data[0][0], data[0][1]]})
      .then((res) => {     
        
     if(res.data != 'no result'){
      Object.values(chartData.months).map(m =>{      
        res.data.rows.map(r => { 
          if(chartData.values.findIndex(element => element.user_id == r.user_id) == -1){
            chartData.values.push({user_id: r.user_id, Käyttäjä: r.name, [m]: 0})                       
          }else{
            chartData.values[chartData.values.findIndex(element => element.user_id == r.user_id)][m] = 0
          }
        })
      })
  
      res.data.rows.map(r => {     
        chartData.values[chartData.values.findIndex(element => element.user_id == r.user_id)][chartData.months[r.month + "/" + r.year.substring(2)]] = parseInt(r.sum)
      })
  
      for(let i = 0; i < chartData.values.length; i++){
        delete chartData.values[i].user_id
        setData(chartData)
      }
     }else{
      setData([])
     }

      }).catch((error) => {
          console.log(error)
      });
    }
    
  };

  const dataGrid = (
    Object.keys(data).length > 0 ?  
    <React.Fragment>  
      <DataGrid
        className={'dx-card wide-card'}
        dataSource={data.values}        
        showBorders={true}
        allowColumnResizing={true}
      >    
      </DataGrid>     
    </React.Fragment>    
   : null
  )

  return (
    <div>
      <h2 className={'content-block'}>Kotiasemien käyttö      
        <Button
          width={140}
          text="Aikajakso"
          type="default"
          stylingMode="outlined"
          style={{float: "right"}}
          onClick={() => {setPopupVisible(true)}}
        /> 
      </h2>   
      <Calendar type={'month'} setPopupVisible={() => setPopupVisible(!popupVisible)} state={popupVisible} setData={(d) => fetchData(d)}/>
      {dataGrid}
    </div>
  );
}

export default MeasurementsByUsers;