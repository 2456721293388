import defaultUser from '../utils/default-user';
import axios from 'axios';


async function getAuth(data) {
  return new Promise(async function(resolve, reject){
    axios.post('/api/auth', data)
    .then((res) => {
        if(res.data.authentication == 'success'){
          localStorage.setItem("token", res.data.token); 
          resolve({authentication: true, user: res.data.user});
        }else{
          resolve({authentication: false});       
        }     
    }).catch((error) => {
      resolve({authentication: false});
    });        
  })
}

export async function signIn(email, password) {
  return new Promise(async function(resolve, reject){
    getAuth({function: "signin", email: email, password: password}).then(function(result){
      try {  
        if(result.authentication){
          resolve({
            isOk: result.authentication,
            data: result.user
          });
        }else{
          resolve({
            isOk: result.authentication,
            message: "Authentication failed"
          });
        }
        
      }
      catch {
        resolve( {
          isOk: result,
          message: "Authentication failed"
        });
      }
    });
  });
}

export async function getUser() {
  return new Promise(async function(resolve, reject){
    getAuth({function: "getuser", authorization: localStorage.getItem('token')}).then(function(result){
      try {  
        if(result.authentication){
          resolve({
            isOk: result.authentication,
            data: result.user
          });
        }else{
          resolve({
            isOk: result.authentication,
            message: "Authentication failed"
          });
        }
        
      }
      catch {
        resolve( {
          isOk: result,
          message: "Authentication failed"
        });
      }
    });
  });
}

export async function createAccount(email, password) {
  try {
    // Send request
    console.log(email, password);

    return {
      isOk: true
    };
  }
  catch {
    return {
      isOk: false,
      message: "Failed to create account"
    };
  }
}

export async function changePassword(email, recoveryCode) {
  try {
    // Send request
    console.log(email, recoveryCode);

    return {
      isOk: true
    };
  }
  catch {
    return {
      isOk: false,
      message: "Failed to change password"
    }
  }
}

export async function resetPassword(email) {
  try {
    // Send request
    console.log(email);

    return {
      isOk: true
    };
  }
  catch {
    return {
      isOk: false,
      message: "Failed to reset password"
    };
  }
}
