export const navigation = [
  {
    text: 'Koti',
    path: '/home',
    icon: 'home'
  },
  {
    text: 'Latausasemat',
    icon: 'folder',
    items: [     
      {
        text: 'Toimipisteet',
        path: '/mbl'
      },
      {
        text: 'Kotiasemat',
        path: '/mbu'
      },
      {
        text: 'Tilastot',
        path: '/mgu'
      },
      {
        text: 'Raportointi',
        path: '/reporting'
      }
    ]},
    {
    text: 'Aurinkopaneelit',
    icon: 'folder',
    items: [     
      {
        text: 'Toimipisteet',
        path: '/sbl'
      },
      {
        text: 'Tilastot',
        path: '/sgu'
      },
      {
        text: 'Raportointi',
        path: '/sreporting'
      }      
    ]
  }
  ];
