import React, { useState } from 'react';
import './profile.scss';
import Form from 'devextreme-react/form';

export default () => {
  const [notes, setNotes] = useState(
    ''
  );
  const employee = {
    Etunimi: 'Juha',
    Sukunimi: 'Saarikoski',
    Luotu: '1.1.2021',
    Käyttöoikeustaso: 'Käyttäjä'  
  };

  return (
    <React.Fragment>
      <h2 className={'content-block'}>Profiili</h2>

      <div className={'content-block dx-card responsive-paddings'}>
        <Form
          id={'form'}
          defaultFormData={employee}
          onFieldDataChanged={e => e.dataField === 'Notes' && setNotes(e.value)}
          labelLocation={'top'}
          colCountByScreen={colCountByScreen}
        />
      </div>
    </React.Fragment>
  );
};

const colCountByScreen = {
  xs: 1,
  sm: 2,
  md: 3,
  lg: 4
};
