import { withNavigationWatcher } from './contexts/navigation';
import { HomePage, TasksPage, ProfilePage, MeasurementsByLocations, MeasurementsByUsers, MostChargedUsers, Reporting } from './pages';

const routes = [  
  {
    path: '/mbl',
    component: MeasurementsByLocations
  },
  {
    path: '/mbu',
    component: MeasurementsByUsers
  },
  {
    path: '/mgu',
    component: MostChargedUsers
  },
  {
    path: '/reporting',
    component: Reporting
  },
  {
    path: '/profile',
    component: ProfilePage
  },
  {
    path: '/home',
    component: HomePage
  }
];

export default routes.map(route => {
  return {
    ...route,
    component: withNavigationWatcher(route.component)
  };
});
