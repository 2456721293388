import React, { useState, useEffect, useRef } from "react";
import axios from 'axios';
import { Chart, Series, CommonSeriesSettings, Label, Format, Legend, Export } from 'devextreme-react/chart';
import Calendar from '../../components/calendar/calendar'
import { Button } from 'devextreme-react/button';

const ChargingStationUsage = () => {
  const [data, setData] = useState([]);
  const [popupVisible, setPopupVisible] = useState(false);

  function fetchData(data) {
    console.log(data[0])
    axios.post('/api/fetchdata', {function: "mbl", authorization: localStorage.getItem('token'), date: [data[0], data[1], data[2], data[3]]})
        .then((res) => {
          console.log(res)
          if(true){
            let chartData = {titles: [], data: []};
            chartData.titles.push("Mittausarvot toimipisteittäin")            
            res.data.rows.map(r => {
              if(chartData.titles.findIndex(element => element == r.quarter) == -1){
                chartData.titles.push(r.quarter)
              }
              if(chartData.data.findIndex(element => element.state == r.name) == -1){
                chartData.data.push({state: r.name, [r.quarter]: parseInt(r.sum)})              
              }else{
                chartData.data[chartData.data.findIndex(element => element.state == r.name)][r.quarter] = parseInt(r.sum)
              }
            })           
            setData(chartData)
          }else{
            setData([])
          }
          
        }).catch((error) => {
          setData([])
    }); 
  }


  function onPointClick(e) {
    e.target.select();
  } 
 

  return ( 

    Object.keys(data).length > 0 ?
    <div>
      <Calendar type={'quarter'} state={true} setPopupVisible={() => setPopupVisible(!popupVisible)} state={popupVisible} setData={(d) => fetchData(d)}/>
      <Button
    width={140}
    text="Aikajakso"
    type="default"
    stylingMode="outlined"
    style={{float: "right"}}
    onClick={() => {setPopupVisible(true)}}
  /> 
      <Chart id="chart"
      title={data.titles[0]}
      dataSource={data.data}
      onPointClick={onPointClick}
    >
      <CommonSeriesSettings
        argumentField="state"
        type="bar"
        hoverMode="allArgumentPoints"
        selectionMode="allArgumentPoints"
      >
        <Label visible={true}>
          <Format type="fixedPoint" precision={0} />
        </Label>
      </CommonSeriesSettings>
      <Series
        argumentField="state"
        valueField={data.titles[1]}
        name={data.titles[1]}
      />   
      {(() => {
        if(typeof(data.titles[2]) != 'undefined') {
          return (<Series
          valueField={data.titles[2]}
          name={data.titles[2]}
        />)
        }
      })()}          
      <Legend verticalAlignment="bottom" horizontalAlignment="center"></Legend>
      <Export enabled={true} />
    </Chart>    
    </div>
    
    :<div>
  <Button
    width={140}
    text="Aikajakso"
    type="default"
    stylingMode="outlined"
    style={{float: "right"}}
    onClick={() => {setPopupVisible(true)}}
  /> 
  <Calendar type={'quarter'} state={true} setPopupVisible={() => setPopupVisible(!popupVisible)} state={popupVisible} setData={(d) => fetchData(d)}/>  
    </div> 
    
  );
}
export default ChargingStationUsage;
